import * as React from "react";

type Props = {
  to: string;
  children: React.ReactNode;
};

export const Item = ({ to, children }: Props) => {
  const tabClasses = `py-4 md:py-6 px-4 text-sm 3xl:px-6 3xl:text-base text-white hover:text-white`;

  const tabActive = `text-decoration: underline`;
  const isActive = window.location.href.includes(to);
  const classes = isActive === true ? `${tabClasses} ${tabActive}` : tabClasses;

  return (
    <a href={to} className={classes}>
      {children}
    </a>
  );
};
