import React from "react";

type IconProps = {
  icon: React.ReactElement;
  className?: string;
};

function Icon({ icon, className }: IconProps) {
  return <i className={`w-5 inline-block ${className}`}>{icon}</i>;
}

type IconWithChildrenProps = IconProps & {
  children: React.ReactNode;
};

function IconWithText({ icon, children }: IconWithChildrenProps) {
  return (
    <div className="flex items-center">
      <Icon icon={icon} className="mr-2" />
      {children}
    </div>
  );
}

export { Icon as default, IconWithText };
